<template>
  <li ref="seats" class="tabsDashboard__tab js-tab-item" data-type="seats">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>
    <div class="tabsDashboard__tab-header">
      <div class="tabsDashboard__tab-title">
        <div class="tabsDashboard__tab-text"><b>Sell</b> seats</div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon name="ticket"/>
        </div>
      </div>
      <router-link class="tabsDashboard__tab-link" to="/dashboard/seats">See full</router-link>
    </div>
    <div class="tabsDashboard__tab-inner">
      <div class="tabsDashboard__seats">
        <div
          v-for="item of seats"
          :key="item.id"
          class="tabsDashboard__seats-item"
        >
          <div class="tabsDashboard__seats-item-row">
            <div class="tabsDashboard__seats-route">
              <div class="tabsDashboard__seats-route-item">
                <div class="tabsDashboard__seats-route-title">Departure:</div>
                <div class="tabsDashboard__seats-route-text">{{item.from}}</div>
              </div>
              <div class="tabsDashboard__seats-route-item">
                <div class="tabsDashboard__seats-route-title">Arrival:</div>
                <div class="tabsDashboard__seats-route-text">{{item.to}}</div>
              </div>
            </div>
          </div>
          <div class="tabsDashboard__seats-item-row">
            <div class="tabsDashboard__seats-date">
              <div class="tabsDashboard__seats-date-title">Dates:</div>
              <div class="tabsDashboard__seats-date-text">{{item.startDate}} - {{item.endDate}}</div>
            </div>
          </div>
          <div class="tabsDashboard__seats-item-row">
            <div class="tabsDashboard__seats-info">Open a seat and set it up for sale</div>
            <router-link class="tabsDashboard__seats-button" to="#">
              <span class="text">Open</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="tabsDashboard__tab-footer">
      <router-link class="tabsDashboard__tab-link forMobile" to="#">See full</router-link>
    </div>
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    props: ['visible'],
    name: 'TabsDashboardSeats',
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.seats)
      },
    },
    methods: {
      closeSection () {
        this.$emit('hideSection', this.$refs.seats.getAttribute('data-type'))
      },
    },
    computed: {
      seats () {
        return this.$store.state.dashboard.seats
      },
    },
    components: {
      SvgIcon,
    },
  }
</script>
