<template>
  <li ref="alerts" class="tabsDashboard__tab js-tab-item" data-type="alerts">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>
    <div class="tabsDashboard__tab-header">
      <div class="tabsDashboard__tab-title">
        <div class="tabsDashboard__tab-text"><b>Route</b> alerts</div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon name="alarm"/>
        </div>
      </div>
      <router-link class="tabsDashboard__tab-link" to="/dashboard/alerts">See full</router-link>
    </div>
    <div class="tabsDashboard__tab-inner">
      <div class="tabsDashboard__alerts">
        <div class="alertsDashboard__list">
          <div v-if="!routes.length" class="alertsDashboard__list-empty js-alert-empty" style="color: black">No alerts <b>yet</b></div>
          <div
            v-for="item of routes"
            :key="item.id"
            class="alertsDashboard__list-item js-alert-item"
            :class="{'alertPause' : !item.watching}"
            @mouseenter="showPanel"
            @mouseleave="hidePanel"
          >
            <div class="alertsDashboard__list-item-info">
              <div class="alertsDashboard__list-item-route">
                <div class="alertsDashboard__list-item-route-title">{{item.from}}</div>
                <div class="alertsDashboard__list-item-route-direction">
                  <SvgIcon name="double-long-arrows"/>
                </div>
                <div class="alertsDashboard__list-item-route-title">{{item.to}}</div>
              </div>
              <div class="alertsDashboard__list-item-flights">
                <div class="alertsDashboard__list-item-flights-text">{{item.flightCount}} new flight</div>
                <div class="alertsDashboard__list-item-flights-pause">
                  <SvgIcon name="pause"/>
                </div>
              </div>
              <div class="alertsDashboard__list-item-text">{{item.description}}</div>
              <div class="alertsDashboard__list-item-price">Up to ${{item.cost}}</div>
            </div>
            <div class="js-alert-nav">
              <div class="alertsDashboard__list-item-nav">
                <div class="alertsDashboard__list-item-nav-inner">
                  <div class="alertsDashboard__list-item-nav-item js-alert-pause">
                    <span class="alertsDashboard__list-item-nav-item-icon">
                      <SvgIcon name="pause"/>
                      <SvgIcon name="play"/>
                    </span>
                    <span
                      class="alertsDashboard__list-item-nav-item-text js-alert-pause-text"
                      v-if="item.watching"
                      @click="$store.commit('changeWatching', item.id)"
                    >Pause watching</span>
                    <span
                      class="alertsDashboard__list-item-nav-item-text js-alert-pause-text"
                      v-else
                      @click="$store.commit('changeWatching', item.id)"
                    >Continue watching</span>
                  </div>
                  <div class="alertsDashboard__list-item-nav-item js-alert-delete" @click="showModal(item.id)">
                    <span class="alertsDashboard__list-item-nav-item-icon"><SvgIcon name="delete"/></span>
                    <span class="alertsDashboard__list-item-nav-item-text">Delete</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabsDashboard__tab-footer">
      <router-link class="tabsDashboard__tab-link forMobile" to="#">See full</router-link>
    </div>
    <QuestionPopup
      :name="'QuestionPopupRoute'"
      :title="'The route will be removed'"
      :text="'Are you sure?'"
      @remove="remove"
    />
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import QuestionPopup from '@/components/common/modals/QuestionPopup'
  import './TabsDashboardAlerts.styl'

  export default {
    props: ['visible'],
    name: 'TabsDashboardAlerts',
    computed: {
      routes () {
        return this.$store.state.dashboard.routes
      },
    },
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.alerts)
      },
    },
    methods: {
      closeSection () {
        this.$emit('hideSection', this.$refs.alerts.getAttribute('data-type'))
      },
      showPanel (event) {
        const html = document.querySelector('html')
        if (!html.classList.contains('d-mobile')) {
          const item = event.currentTarget.querySelector('.js-alert-nav')
          this.$root.animations.slideDown(item)
        }
      },
      hidePanel (event) {
        const html = document.querySelector('html')
        if (!html.classList.contains('d-mobile')) {
          const item = event.currentTarget.querySelector('.js-alert-nav')
          this.$root.animations.slideUp(item)
        }
      },
      showModal (item) {
        this.$modal.show('QuestionPopupRoute')
        this.$store.commit('insertRouteToRemove', item)
      },
      remove () {
        this.$store.commit('removeWatchig')
      },
    },
    components: {
      SvgIcon,
      QuestionPopup,
    },
  }
</script>
