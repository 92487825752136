<template>
  <div class="tabsDashboard">
    <vue-scroll :ops="ops">
      <div class="tabsDashboard__header">
        <div class="tabsDashboard__title">Customize your dashboard</div>
        <div class="tabsDashboard__text">
          <span class="text text--desktop">You can put any desired tab on the dashboard. Just drag it from the left vertical menu</span>
          <span class="text text--mobile">You can put any desired tab on the dashboard. “ADD NEW TAB” at the bottom of the page</span>
        </div>
      </div>
      <ul class="tabsDashboard__content js-tab-list">
        <div style="display:none;">
        <TabsDashboardFlights :type="'flyer'" :visible="sections.flights" @hideSection="hideSection"/>
        <TabsDashboardProfile :type="'flyer'" :visible="sections.profile" @hideSection="hideSection"/>
        <TabsDashboardMessages :visible="sections.messages" @hideSection="hideSection"/>
        <TabsDashboardFlyRewards :type="'flyer'" :visible="sections.flyRewards" @hideSection="hideSection"/>
        <TabsDashboardRequests :type="'flyer'" :miniTab="true" :visible="sections.requests" @hideSection="hideSection"/>
        <TabsDashboardSettings :visible="sections.settings" @hideSection="hideSection"/>
        <TabsDashboardPassId :visible="sections.passId" @hideSection="hideSection"/>
        <TabsDashboardPayment :type="'flyer'" :visible="sections.payment" @hideSection="hideSection"/>
        <TabsDashboardAlerts :visible="sections.alerts" @hideSection="hideSection"/>
        <TabsDashboardAuctions :type="'flyer'" :visible="sections.auctions" @hideSection="hideSection"/>
        <TabsDashboardSeats :visible="sections.seats" @hideSection="hideSection"/>
        </div>
        <TabsDashboardAdd :type="'flyer'" @showSection="showSection"/>
      </ul>
    </vue-scroll>
  </div>
</template>

<script>
  import TabsDashboardFlights from '@/components/dashboard/tabs/TabsDashboardFlights'
  import TabsDashboardProfile from '@/components/dashboard/tabs/TabsDashboardProfile'
  import TabsDashboardMessages from '@/components/dashboard/tabs/TabsDashboardMessages'
  import TabsDashboardFlyRewards from '@/components/dashboard/tabs/TabsDashboardFlyRewards'
  import TabsDashboardRequests from '@/components/dashboard/tabs/TabsDashboardRequests'
  import TabsDashboardSettings from '@/components/dashboard/tabs/TabsDashboardSettings'
  import TabsDashboardPassId from '@/components/dashboard/tabs/TabsDashboardPassId'
  import TabsDashboardPayment from '@/components/dashboard/tabs/TabsDashboardPayment'
  import TabsDashboardAlerts from '@/components/dashboard/tabs/TabsDashboardAlerts'
  import TabsDashboardAuctions from '@/components/dashboard/tabs/TabsDashboardAuctions'
  import TabsDashboardSeats from '@/components/dashboard/tabs/TabsDashboardSeats'
  import TabsDashboardAdd from '@/components/dashboard/tabs/TabsDashboardAdd'
  import '@/views/dashboard/TabsDashboard.styl'

  export default {
    name: 'DashboardFlyer',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      sections: {
        flights: true,
        profile: true,
        messages: true,
        flyRewards: true,
        requests: true,
        settings: true,
        passId: true,
        payment: true,
        alerts: true,
        auctions: true,
        seats: true,
      },
    }),
    methods: {
      hideSection (value) {
        this.sections[`${value}`] = false
      },
      showSection (value) {
        this.sections[`${value}`] = true
      },
    },
    components: {
      TabsDashboardFlights,
      TabsDashboardProfile,
      TabsDashboardMessages,
      TabsDashboardFlyRewards,
      TabsDashboardRequests,
      TabsDashboardSettings,
      TabsDashboardPassId,
      TabsDashboardPayment,
      TabsDashboardAlerts,
      TabsDashboardAuctions,
      TabsDashboardSeats,
      TabsDashboardAdd,
    },
  }
</script>
