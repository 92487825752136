<template>
  <li ref="passId" class="tabsDashboard__tab js-tab-item" data-type="passId">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>
    <div class="tabsDashboard__tab-header">
      <div class="tabsDashboard__tab-title">
        <div class="tabsDashboard__tab-text">Passenger <b>indentification</b></div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon name="card"/>
        </div>
      </div>
      <router-link class="tabsDashboard__tab-link" to="/dashboard/passenger-id">See full</router-link>
    </div>
    <div class="tabsDashboard__tab-inner">
      <div class="tabsDashboard__passId">
        <div class="tabsDashboard__passId-item">
          <div class="tabsDashboard__passId-title">Drivers license:&ensp;&#8212;</div>
        </div>
        <div class="tabsDashboard__passId-item">
          <div class="tabsDashboard__passId-title">Birth certificate:&ensp;&#8212;</div>
        </div>
        <div class="tabsDashboard__passId-item">
          <div class="tabsDashboard__passId-title">Passport:</div>
          <div class="tabsDashboard__passId-info">
            <div class="tabsDashboard__passId-info-item">
              <span class="tabsDashboard__passId-info-title">Passport number&ensp;</span>
              <span>{{passenger.passportNumber}}</span>
            </div>
            <div class="tabsDashboard__passId-info-item">
              <span class="tabsDashboard__passId-info-title">Expiration date&ensp;</span>
              <span>{{passenger.date}}</span>
            </div>
          </div>
          <span
            class="tabsDashboard__passId-file"
            @click="openLightboxOnSlide">
            <div
              class="tabsDashboard__passId-file-icon"
              :style="{backgroundImage: `url(${passenger.file})`}"
            ></div>
            <div class="tabsDashboard__passId-file-caption">
              <div class="tabsDashboard__passId-file-title">
                <span class="text">Passport.pdf</span>
                <span class="icon"><SvgIcon name="checked"/></span>
              </div>
              <div class="tabsDashboard__passId-file-size">25MB</div>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="tabsDashboard__tab-footer">
      <router-link class="tabsDashboard__tab-link forMobile" to="#">See full</router-link>
    </div>
    <FsLightbox
      :toggler="toggler"
      :sources="[passenger.file]"
      :onOpen="opened"
    />
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import FsLightbox from 'fslightbox-vue'

  export default {
    props: ['visible'],
    name: 'TabsDashboardPassId',
    data: () => ({
      toggler: true,
    }),
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.passId)
      },
    },
    computed: {
      passenger () {
        return this.$store.state.dashboard.passenger
      },
    },
    methods: {
      closeSection () {
        this.$emit('hideSection', this.$refs.passId.getAttribute('data-type'))
      },
      openLightboxOnSlide (number) {
        this.toggler = !this.toggler
      },
      opened () {
        const popup = document.querySelector('.fslightbox-container')
        document.querySelector('body').appendChild(popup)
      },
    },
    components: {
      SvgIcon,
      FsLightbox,
    },
  }
</script>
