<template>
  <li ref="settings" class="tabsDashboard__tab js-tab-item" data-type="settings">
    <div class="tabsDashboard__tab-remove js-tab-remove" @click="closeSection">
      <span class="icon"><SvgIcon name="close"/></span>
    </div>
    <div class="tabsDashboard__tab-header">
      <div class="tabsDashboard__tab-title">
        <div class="tabsDashboard__tab-text"><b>Settings</b> &#38; Security</div>
        <div class="tabsDashboard__tab-icon">
          <SvgIcon name="locked"/>
        </div>
      </div>
      <router-link class="tabsDashboard__tab-link" to="/dashboard/settings">See full</router-link>
    </div>
    <div class="tabsDashboard__tab-inner">
      <div class="tabsDashboard__settings">
        <div class="tabsDashboard__settings-form">
          <form class="form form--typeAlerts js-ajaxForm" data-href="/" data-action="alerts" id="typeAlertsForm">
            <label class="form__label form__label--checkbox">
              <input
                class="form__checkbox"
                type="checkbox"
                v-model="type"
                value="in-application"
              />
              <i class="form__checkbox-icon"></i>
              <span class="form__checkbox-box">Receive in-application alerts and notifications</span>
            </label>
            <label class="form__label form__label--checkbox">
              <input
                class="form__checkbox"
                type="checkbox"
                v-model="type"
                value="email"
              />
              <i class="form__checkbox-icon"></i>
              <span class="form__checkbox-box">Receive email alerts and notifications</span>
            </label>
            <label class="form__label form__label--checkbox">
              <input
                class="form__checkbox"
                type="checkbox"
                v-model="type"
                value="text-message"
              />
              <i class="form__checkbox-icon"></i>
              <span class="form__checkbox-box">Receive text message alerts and notifications</span>
            </label>
          </form>
        </div>
        <router-link class="tabsDashboard__settings-button" to="#">
          <span class="text">Change Password</span>
        </router-link>
      </div>
    </div>
    <div class="tabsDashboard__tab-footer">
      <router-link class="tabsDashboard__tab-link forMobile" to="#">See full</router-link>
    </div>
  </li>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import './TabsDashboardSettings.styl'

  export default {
    props: ['visible'],
    name: 'TabsDashboardSettings',
    data: () => ({
      type: ['in-application', 'email'],
    }),
    watch: {
      visible () {
        this.$root.animations.fadeToggle(this.$refs.settings)
      },
    },
    methods: {
      closeSection () {
        this.$emit('hideSection', this.$refs.settings.getAttribute('data-type'))
      },
    },
    mounted () {
      this.$root.fields.changeChecked('.form__checkbox')
    },
    components: {
      SvgIcon,
    },
  }
</script>
